<template>
<div class="my-4">
  <b-container>
    <b-row class="mb-4" v-if="!loadingNews">
      <b-col>
        <b-card class="mb-3">
          <event
            :event="event"
            :permissions="permissions"
            :username="user.username"
            :showRelatedNews="true"
          />
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="loadingNews" class="text-center mb-4" align-h="center">
      <b-col cols="1">
        <div><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></div>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import moment from 'moment'
import Event from '@/components/EventLarge.vue'

export default {
  components: {
    Event
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    this.firstLoad()
  },
  data () {
    return {
      formEventValidated: false,
      gridLoaderColor: 'black',
      gridLoaderSize: '10px',
      event: {},
      authors: {},
      loadingNews: true,
      moment: moment,
      permissions: {}
    }
  },
  methods: {
    firstLoad: async function () {
      this.$logger.debug('first load started')
      console.log('trying')
      try {
        let apiName = 'cosmos'
        let path = `/event/${this.$route.params.id}/full`
        let response = await this.$Amplify.API.get(apiName, path)
        this.$logger.debug('firstLoad:')
        this.$logger.debug({ response })
        this.event = response

        console.log(this.article)
        this.loadingNews = false
        this.$stat.log({ page: 'article', action: 'open article', model: 'article', model_id: this.$route.params.id })
      } catch (e) {
        this.$logger.warn('saved ERROR: ' + e)
      }
    }
  }
}
</script>

<style>
</style>
